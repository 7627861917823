<template>
  <b-card>
    <g-form @submit="save">

      <div class="header">
        <label> {{ $t('mainData') }} </label>
      </div>

      <b-row>
        <!-- message number  -->
        <b-col
          v-if="id>0"
          md="4"
        >
          <b-form-group>
            <g-field
              id="code"
              type="number"
              name="code"
              label-text="messageNumber"
              readonly
              :value.sync="selectedItem.code"
            />
          </b-form-group>
        </b-col>

        <!-- message date  -->
        <b-col md="4">
          <b-form-group>
            <g-picker
              label-text="messageDate"
              :value.sync="selectedItem.messageDate"
              :disabled="!currentBranch.setDefaultDate"
            />
          </b-form-group>
        </b-col>

        <!-- message time  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              label-text="messageTime"
              name="messageTime"
              readonly
              :value.sync="selectedItem.messageTime"
            />
          </b-form-group>
        </b-col>

        <!-- category selection -->
        <b-col
          class="messages gb-package"
          md="4"
        >
          <label> {{ $t("sendMessageForGroupOfStudentsIn") }} </label>
          <b-button-group>
            <b-button
              :class="['gb-btn-child', {btnActive: selectedItem.groupId === 1}]"
              variant="flat-primary"
              @click="choosedGroupByClick('stage')"
            >
              {{ $t("stage") }}
            </b-button>

            <b-button
              :class="['gb-btn-child', {btnActive: selectedItem.groupId === 2}]"
              variant="flat-primary"
              @click="choosedGroupByClick('row')"
            >
              {{ $t("row") }}
            </b-button>

            <b-button
              :class="['gb-btn-child', {btnActive: selectedItem.groupId === 3}]"
              variant="flat-primary"
              @click="choosedGroupByClick('class')"
            >
              {{ $t("class") }}
            </b-button>
          </b-button-group>
        </b-col>

        <!-- group selection  -->
        <b-col
          v-if="selectedGroupName !== ''"
          md="4"
        >
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              name="selectedGroup"
              rules="required"
              :label-text="selectedGroupName"
              :options="selectedGroup"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.groupValueId"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- grid of students according to selected group -->
      <section v-if="selectedGroupName !== ''">
        <div class="header mt-2">
          <label> {{ `${$t('studentsExistIn')} ${selectedGroupValueName}` }} </label>
        </div>

        <b-row>
          <b-col md="12">
            <g-table
              ref="studentsToMessage-table"
              :items="gridItems"
              :columns="gridColumns"
              :noAaction="true"
              perPage="15"
              :totalRows="totalRows"
              :createButton="{ visiable: false }"
              :excelButton="{ visiable: false }"
              :pdfButton="{ visiable: false }"
              :searchInput="{ visiable: true }"
            >
              <template #isIncluded="{ item }">
                <span>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.isIncluded"
                      v-permission=" 'editMessageInclude' "
                      size="md"
                      class="chk-style"
                      inline
                      @change="(v) => {
                        getStatus(item)
                      }"
                    />
                  </b-form-group>
                </span>
              </template>
            </g-table>
          </b-col>
        </b-row>

        <!-- select all and un-select all buttons -->
        <!-- <b-row class="mb-2">
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              class="mx-1 ml-2"
              variant="yahoo"
              @click="selectAll()"
            >
              {{ $t("selectAll") }}
            </b-button>
            <b-button
              class="mx-1"
              variant="outline-yahoo"
              @click="unSelectAll()"
            >
              {{ $t("unSelectAll") }}
            </b-button>
          </b-col>
        </b-row> -->
      </section>

      <div class="header">
        <label> {{ $t('messageSection') }} </label>
      </div>

      <b-row>
        <!-- message title -->
        <b-col md="12">
          <b-form-group>
            <g-field
              id="messageTitle"
              name="messageTitle"
              label-text="messageTitle"
              rules="required"
              :value.sync="selectedItem.messageTitle"
            />
          </b-form-group>
        </b-col>

        <!-- message content -->
        <b-col md="12">
          <b-form-group :label="$t('messageContent')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.messageContent"
              rows="5"
              max-rows="10"
            />
            <small
              class="textarea-counter-value float-right"
            ><span class="char-count">{{
              selectedItem.messageContent ? selectedItem.messageContent.length : 0
            }}</span>
              / 1000
            </small>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- operations -->
      <b-row
        v-if="!selectedItem.isSent"
        class="mt-2"
      >
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-permission="$route.meta.permission"
            class="ml-1"
            type="submit"
            data-action-type="save"
            variant="gradient-primary"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-25"
            />
            {{ $t("save") }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            class="ml-1"
            variant="outline-primary"
            data-action-type="saveAndPrint"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-25"
            />
            {{ $t("saveAndPrint") }}
          </b-button>
        </b-col>
      </b-row>

    </g-form>
  </b-card>
</template>

<script>
  import reportMixin from '@/mixin/reportMixin';
  import GTable from '@/pages/Shared/Table.vue';
  import { rows } from '@/libs/acl/Lookups';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

  export default {
    components: {
      GTable,
    },
    mixins: [
      reportMixin,
    ],
    props: ['id'],

    data() {
      return {
        gridItems: [],
        selectedItem: {
          groupId: 0,
          groupValueId: 0,
          messageContent: '',
          isIncluded: true
        },
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        rows: rows,
        modal: false,

        stages: [],
        schoolRows: [],
        classRooms: [],
        selectedGroup: [],
        selectedGroupName: '',
        selectedGroupValueName: '',
      };
    },

    computed: {
      gridColumns() {
        return [
          { key: 'code', label: this.$t('code'), sortable: true },
          { key: 'arabicName', label: this.$t('studentName'), sortable: true },
          { key: 'stageNameAr', label: this.$t('stage'), sortable: true },
          { key: 'rowNameAr', label: this.$t('row'), sortable: true },
          { key: 'classroomNameAr', label: this.$t('class'), sortable: true },
          { key: 'mobileNumber', label: this.$t('mobile') },
          { key: 'isIncluded', label: this.$t('includeStudent') },
        ];
      },
    },

    watch: {
      /* after selecting group:
         1. get students for this group and set it for grid
         2. set the title of table with group name */
      'selectedItem.groupValueId'() {
        this.setGridItemsWithRelatedStudents(this.selectedItem.groupValueId);
        if (this.selectedGroup.length > 0) {
            this.selectedGroupValueName = this.selectedGroup.find((val) => val.id === this.selectedItem.groupValueId).arabicName;
        }
      },

      /* after selecting category
         1. set category name title based on selection
         2. then load category datasource based on category */
      'selectedItem.groupId'() {
        this.setGroupNameByGroupId(this.selectedItem.groupId);
        this.setSelectedGroupDataSourceByGroupId(this.selectedItem.groupId);
      }
    },

    beforeMount() {
      this.fiscalYearStart = this.currentYear.startDate;
      this.fiscalYearEnd = this.currentYear.endDate;
      this.loadObjs();
    },

    async mounted() {
      this.selectedItem.messageTime = this.getTime();

      // wait for APIs to be loaded then set selected item
      await this.DelaySome(100);
      if (this.id > 0) {
        this.getData();
      }
    },

    methods: {
      validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
      async DelaySome(timeMs) {
        await new Promise((resolve) => setTimeout(resolve, timeMs))
      },

      getTop1IFromSelectedGroup() {
        if (this.selectedGroup.length > 0) {
          this.selectedItem.groupValueId = this.selectedGroup[0].id;
        }
      },

      setSelectedGroupDataSourceByGroupId(groupId) {
        switch (groupId) {
          case 1:
            this.selectedGroup = this.stages;
            break;

          case 2:
            this.selectedGroup = this.schoolRows;
            break;

          case 3:
            this.selectedGroup = this.classRooms;
            break;

          default:
            this.selectedGroup = []
            break;
        }
      },

      setGroupIdByGroupName(groupName) {
        switch (groupName) {
          case 'stage':
            this.selectedItem.groupId = 1;
            break;

          case 'row':
            this.selectedItem.groupId = 2;
            break;

          case 'class':
            this.selectedItem.groupId = 3;
            break;

          default:
            this.selectedItem.groupId = 0;
            break;
        }
      },

      setGroupNameByGroupId(groupId) {
        switch (groupId) {
          case 1:
            this.selectedGroupName = 'stage';
            break;

          case 2:
            this.selectedGroupName = 'row';
            break;

          case 3:
            this.selectedGroupName = 'class';
            break;

          default:
            this.selectedGroupName = '';
            break;
        }
      },

      choosedGroupByClick(groupName) {
        this.setGroupIdByGroupName(groupName);
        this.setSelectedGroupDataSourceByGroupId(this.selectedItem.groupId);
        this.getTop1IFromSelectedGroup();
      },

      getData() {
        this.get({
            url: 'Messages',
            id: this.$route.params.id,
        }).then((data) => {
            this.selectedItem = data;
            this.selectedItem.groupValueId = data.groupValueId;
            this.setSelectedGroupDataSourceByGroupId(data.groupId);
        })
      },

      getStudentsIncludedInMessage() {
        const studentIncludedMessage = []
        this.selectedItem.studentMessages.forEach(element => {
          studentIncludedMessage.push(element.studentId);
        });
        return studentIncludedMessage
      },

      getStatus(item) {
        if (item.isIncluded) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t('included')} ${item.arabicName}`,
              icon: 'UserPlusIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t('excluded')} ${item.arabicName}`,
              icon: 'UserMinusIcon',
              variant: 'warning',
            },
          })
        }
      },

    setGridItemsWithRelatedStudents() {
      this.get({ url: `Students/getStudentsByGroupId?group=${this.selectedItem.groupId}&id=${this.selectedItem.groupValueId}` }).then((data) => {
        this.gridItems = data;

        if (this.id > 0) {
          const studentsIncludedInMessage = this.getStudentsIncludedInMessage();
          this.gridItems.forEach(element => {
            element.isIncluded = studentsIncludedInMessage.indexOf(element.id) !== -1;
          });
        } else {
          this.selectAll();
        }
      });
    },

    loadObjs() {
      this.getStages();
      this.getRows();
      this.getClassRoms();
      this.getStudents();
    },

    getStages() {
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data;
      });
    },

    getRows() {
      this.get({ url: 'Rows' }).then((data) => {
        this.schoolRows = data;
      });
    },

    getClassRoms() {
      this.get({ url: 'Classrooms' }).then((data) => {
        this.classRooms = data;
      });
    },

    backToList() {
      this.$router.push({ name: 'messages' });
    },

    save(type) {
      if (!this.validateYear(this.selectedItem.messageDate)) return;
      // get data need if not given
      this.selectedItem.branchId = this.branchId;
      if (this.selectedItem.messageContent === '') {
        this.selectedItem.messageContent = this.selectedItem.messageTitle;
      }

      // get selected students to message
      this.selectedItem.studentMessages = []
      this.gridItems.forEach(element => {
        if (element.isIncluded) {
          const studentToMessage = { studentId: element.id }
          this.selectedItem.studentMessages.push(studentToMessage);
        }
      });

      // insert or update secnario
      if (this.id > 0) {
        this.update({
          url: 'Messages',
          data: this.selectedItem,
          id: this.id,
        })
        .then(() => {
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          if (type === 'saveAndPrint') this.print(this.selectedItem.id);
          if (type === 'cancel') {
            window.location.reload();
          } else {
            this.backToList();
          }
        });
      } else {
        this.selectedItem.messageTime = this.getTime();
        this.create({
          url: 'Messages',
          data: this.selectedItem,
        })
          .then((dataId) => {
            this.doneAlert({
              text: this.$t('savedSuccessfully'),
            });
            if (type === 'saveAndPrint') this.print(dataId);
              this.backToList();
          });
      }
    },

    print(messageId) {
      const reportName = this.isRight ? 'Messages-ar' : 'Messages-en';
      const printedItem = {
        id: messageId,
      }
      this.printReport(reportName, printedItem);
    },

    setAllRecordsStateTo(state) {
      this.gridItems.forEach(element => {
          element.isIncluded = state;
        });
    },

    selectAll() {
      this.setAllRecordsStateTo(true);
    },

    unSelectAll() {
      this.setAllRecordsStateTo(false);
    }
    },
  }
</script>

<style>
.chk-style {
  position: absolute;
  margin-left: -10px
}

.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 3px;
}

label {
  font-size: 14px;
}

.messages .gb-btn-child {
  border: 1px solid gainsboro;
}

.messages .btnActive {
  background-color: rgba(115, 103, 240, 0.12)
}

.header {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.header label {
  border-radius: 8px;
  padding: 1rem;
  background-color: rgba(114, 14, 158, 0.1);
  color: #720e9e;
  font-size: 16px;
  word-spacing: 2px;
  letter-spacing: 1.5px;
}
</style>
